:root {
  --bs-primary: #002fa7 !important;
  --bs-secondary: #DBE5FF !important;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto Condensed', sans-serif;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--bs-secondary);
}

/* Hero section start */

header {
  background-color: var(--bs-primary);
}

.logo-main {
  padding-top: 30px;
}

.logo-main img {
  width: 150px;
  height: 35.659px;
  z-index: 999;
  position: relative;
}

.hero-bg {
  position: absolute;
  top: 0;
  right: 0;
}

.vector {
  width: 100%;
}

.hero-img {
  position: absolute !important;
  top: 30% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.overlay {
  position: absolute !important;
  top: 52% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.hero-section {
  background-color: var(--bs-primary);
  width: 100%;
  height: 100vh;
  height: calc(100vh - 7vh);
  margin-top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-right-text h2 {
  font-size: 56px;
  font-weight: 800;
  color: #fff;
  z-index: 999;
  position: relative;
}

.hero-right-text h1 {
  font-size: 72px;
  font-weight: 800;
  color: #fff;
  z-index: 9999;
  position: relative;
}

.hero-right-text p {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  z-index: 999;
  position: relative;
}

.text-box h2 {
  color: #0F172A;
  font-size: 56px;
  font-weight: 800;
}

.text-box p {
  color: #475569;
  font-size: 24px;
  font-weight: 400;
}

.section-top-100 {
  padding-top: 100px;
}

/* .angebot-img {
  width: 100%;
  height: 646px;
} */

.wussten-img {
  width: 100%;
}

.text-box-right h2 {
  color: #0F172A;
  font-size: 56px;
  font-weight: 800;
}

.text-box-right p {
  color: #475569;
  font-size: 24px;
  font-weight: 500;
}

.wussten-list li {
  display: flex;
  align-items: start;
  gap: 10px;
  margin-bottom: 20px;
}

.wussten-list li span {
  color: #475569;
  font-size: 22px;
  font-weight: 400;
}

.kosten {
  background-color: var(--bs-secondary);
  padding: 80px 0px;
  margin-top: 60px;
}

.kosten-text-box h2 {
  color: #000C2A;
  text-align: center;
  font-size: 56px;
  font-weight: 800;
}

.kosten-text-box p {
  color: #000C2A;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.kosten h3 {
  color: #000C2A;
  text-align: center;
  font-size: 64px;
  font-weight: 800;
}

.kosten span {
  color: #000C2A;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  display: block;
}

.kosten-bottom-text {
  color: #000C2A;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.uber-main h2 {
  color: #0F172A;
  text-align: center;
  font-size: 56px;
  font-weight: 800;
}

.card-title {
  color: #475569;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 15px;
}

.card-subtitle {
  color: #0F172A;
  font-size: 24px;
  font-weight: 400;
}

.rechtsanwalt-card {
  margin-left: -100px;
}

/* .reviews-main {
  margin-bottom: 160px;
} */

.reviews-main h2 {
  color: #0F172A;
  font-size: 56px;
  font-weight: 800;
}

.reviews-main .owl-nav {
  position: absolute;
  top: -27%;
  right: 108px;
}

.owl-nav button {
  border-radius: 50% !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
  width: 40px;
  height: 40px;
  border: 1px solid var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}

.reviews-bg-card {
  background-color: var(--bs-secondary);
  height: 430px;
}

.reviews-card .item {
  padding-bottom: 100px !important;
}

.reviews-card {
  position: absolute;
  top: 37%;
  left: 0%;
  right: 0%;
  width: 100%;
}

.reviews-user {
  width: 40px;
  height: 40px;
}

.reviews-user-name {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}

.reviews-user-main q {
  color: #000;
  font-size: 24px;
  font-weight: 400;
}

.reviews-user-footer p {
  color: #475569;
  font-size: 16px;
  font-weight: 400;
}

footer {
  background-color: var(--bs-primary);
  color: #fff;
  padding: 80px 0px;
  margin-top: 180px;
}

.footer-title {
  color: #ffffffe6;
  font-size: 20px;
  font-weight: 500;
}

.footer-title-description {
  color: #ffffffe6;
  font-size: 14px;
  font-weight: 400;
}

.footer-list {
  color: #ffffffe6;
  font-size: 16px;
  font-weight: 400;
}

.page-title {
  color: #FFF;
  text-align: center;
  font-size: 56px;
  font-weight: 800;
  padding: 135px 0px;
  z-index: 1;
  position: relative;
}


@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .page-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
  .page-title {
    font-size: 20px;
    padding: 60px 0px;
  }

  .privacy-policy-list {
    padding: 40px 0px 0px 0px !important;
  }

  footer {
    padding: 40px 0px;
  }

  .privacy-policy-list li {
    margin-left: 30px !important;
    font-size: 20px !important;
  }

  .privacy-policy-list p {
    font-size: 16px !important;
  }

  .privacy-policy-list a {
    font-size: 16px !important;
  }

  .hero-bg {
    width: 100%;
  }

}

.privacy-policy-list {
  padding: 80px 0px 0px 0px;
}

.privacy-policy-list li {
  color: #0F172A;
  font-size: 24px;
  font-weight: 700;
  list-style-type: auto;
  margin-left: 25px;
}

.privacy-policy-list p {
  color: #475569 !important;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 30px;
}

.privacy-policy-list a {
  color: #475569 !important;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}

/* .privacy-policy-list{

} */

/* .blur {
  filter: blur(10px);
  width: 300px;
  border: 1px solid red;
  position: absolute;
  z-index: 99999;
  top: 318px;
} */

.blur {
  position: absolute;
  top: 370px;
  background-color: var(--bs-primary);
  background-position: -145px -95px;
  background-size: 600px 400px;
  filter: blur(10px);
  width: 100%;
  height: 50px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: transparent !important;
}

@media only screen and (max-width: 1640px) and (min-width: 1200px) {
  .reviews-main {
    margin-bottom: 100px;
  }

  .reviews-main .owl-nav {
    top: -30%;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 768px) {
  .hero-img {
    width: 100%;
  }

  .hero-right-text h2 {
    font-size: 30px;
  }

  .hero-right-text h1 {
    font-size: 40px;
  }

  .angebot-img {
    width: 100%;
  }

  .text-box h2,
  .text-box-right h2,
  .kosten-text-box h2,
  .uber-main h2 {
    font-size: 40px;
  }

  .text-box p,
  .text-box-right p,
  .wussten-list li span,
  .kosten-text-box p,
  .kosten span,
  .kosten-bottom-text {
    font-size: 18px;
  }

  .section-top-100 {
    padding-top: 70px;
  }

  .kosten h3 {
    color: #000C2A;
    text-align: center;
    font-size: 40px;
    font-weight: 800;
  }

  .kosten {
    padding: 60px 0px;
  }

  .uber-main img {
    width: 60%;
  }

  .reviews-main .owl-nav {
    top: -32%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 768px) {
  .hero-right-text h2 {
    font-size: 20px;
  }

  .hero-right-text p {
    font-size: 16px;
  }

  .hero-right-text h1 {
    font-size: 30px;
  }

  .hero-section {
    height: calc(100vh - 30vh);
    height: 70vh;
  }

  .section-top-100 {
    padding-top: 50px;
  }

  .rechtsanwalt-card {
    margin-left: 0px;
  }

  .uber-main img {
    width: 50%;
  }

  .card-title {
    font-size: 16px;
  }

  .card-subtitle {
    font-size: 14px;
  }

  .reviews-main h2 {
    font-size: 36px;
  }

  .reviews-main .owl-nav {
    top: -28%;
  }

  .reviews-card {
    top: 30%;
  }

  .reviews-main {
    margin-bottom: 70px;
  }

  .kosten {
    margin-top: 40px;
  }

  footer {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
  .uber-main img {
    width: 100%;
  }

  .angebot-img img {
    width: 100%;
  }

  .hero-right-text h2 {
    font-size: 25px;
    text-align: center;
  }

  .hero-right-text h1 {
    font-size: 30px;
    text-align: center;
  }

  .hero-img {
    z-index: 9;
    width: 100%;
  }

  .overlay {
    z-index: 10;
    width: 100%;
    top: 58% !important;
  }

  .hero-right-text {
    padding-top: 40px;
  }

  .hero-bg-sm {
    width: 50%;
  }

  .hero-right-text p {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }

  .angebot-img {
    width: 100%;
  }

  .section-top-100 {
    padding-top: 60px;
  }

  .text-box h2,
  .text-box-right h2,
  .kosten-text-box h2,
  .uber-main h2,
  .reviews-main h2 {
    font-size: 30px;
  }

  .text-box p,
  .text-box-right p,
  .wussten-list li span,
  .kosten-text-box p,
  .kosten span {
    font-size: 18px;
  }

  .kosten {
    padding: 40px 0px;
    margin-top: 40px;
  }

  .kosten h3 {
    font-size: 40px;
  }

  .rechtsanwalt-card {
    margin-left: 0px;
  }

  .owl-theme .owl-nav {
    display: none;
  }

  .hero-section {
    height: calc(100vh - 0vh);
  }

  .card-title {
    font-size: 18px;
  }

  .card-subtitle {
    font-size: 16px;
  }

  .reviews-main {
    margin-bottom: 40px;
  }

  .reviews-card {
    top: 24%;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1200px) {
  .overlay {
    position: absolute !important;
    top: 62% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
  }
}

/* @media only screen and (max-width: 1399px) and (min-width: 1200px) {
  .overlay {
    position: absolute !important;
    top: 62% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
  }
}
 */

.reviews-user-main {
  height: 100% !important;
}

.owl-stage-outer .owl-stage {
  display: flex;
}

.owl-stage-outer .owl-stage .owl-item .item {
  height: 100%;
}

@media only screen and (max-width: 425px) and (min-width: 375px) {
  footer {
    margin-top: 100px;
  }
}